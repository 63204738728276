import React, { useEffect, useState, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';

import { actions as usersActions } from 'features/users/redux';

import InputSearch from 'components/InputSearch';
import Input from 'components/Input';
import BackBlock from 'components/BackBlock';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import SVG from 'components/SVG';

import arrowSvg from './img/arrow.svg';

import './UsersList.scss';

const b = block('users-list');

const UsersList = () => {
  const [filterValue, onChangeFilterValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.getUsersList());
  }, [dispatch]);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const usersList = useSelector(state => state.users.usersList, shallowEqual);

  const list = useMemo(
    () =>
      usersList.users
        ?.filter(t => ~t.nickname?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
        .map(item => (
          <Link key={item.id} className={b('item')} to={`/user-edit/${item.id}`}>
            <div className={b('item-text')}>{`${item.nickname} (ID: ${item.id})`}</div>
            <div className={b('item-balance')}>{item.balance}</div>
            <SVG svgProps={{ svg: arrowSvg }} className={b('item-arrow')} />
          </Link>
        )) || [],
    [filterValue, usersList],
  );

  return (
    <div className={b()}>
      <div className={b('back-block')}>
        <BackBlock>{locale.userCardBalances}</BackBlock>
      </div>
      <div className={b('search')}>
        <div className={b('search-input')}>
          <InputSearch value={filterValue} onChange={changeFilterValue} placeholder={locale.typeSomething} />
        </div>
      </div>
      <div className={b('subheader-data')}>
        <div className={b('subheader-data-item')}>
          <div className={b('subheader-data-item-nick')}>{locale.userCard}</div>
          <div className={b('subheader-data-item-value')}>{usersList.quantity}</div>
        </div>
        <div className={b('subheader-data-item')}>
          <div className={b('subheader-data-item-nick')}>{locale.accountBalance}</div>
          <div className={b('subheader-data-item-value')}>{usersList.totalBalance}</div>
        </div>
      </div>
      <div className={b('list')}>{list}</div>
    </div>
  );
};

export default UsersList;
