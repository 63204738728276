import React, { useMemo } from 'react';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import InputDate from 'components/InputDate';
import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Select';
import CheckBox from 'components/CheckBox';

import './SlotsHistoryFilter.scss';

const b = block('slots-bet-history-filter-mobile');

const SlotsHistoryFilter = ({ filterData }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const {
    state,
    providerItems,
    gameTypeItems,
    statusItems,
    onChangeGameType,
    onChangeProvider,
    onChangeStatus,
    onChangeFrom,
    onChangeTo,
    onSubmit,
    onChangeUserId,
  } = filterData;

  /* const typeCheckboxes = useMemo(() => {
    return gameTypeItems.map(gt => (
      <div key={gt.value} className={b('check-box-container')}>
        <div className={b('check-box')}>
          <CheckBox
            checked={gt.active}
            name={gt.value}
            onChange={() => {
              const currentItem = state.gameTypes.find(gt2 => gt2.value === gt.value);
              currentItem.active = !currentItem.active;
              onChangeGameType([...state.gameTypes]);
            }}
          />
        </div>
        <span className={b('check-box-text')}>{locale[gt.name]}</span>
      </div>
    ));
  }, [gameTypeItems, onChangeGameType, state.gameTypes, locale]); */

  /* const statusCheckboxes = useMemo(() => {
    return statusItems.map(s => (
      <div key={s.value} className={b('check-box-container')}>
        <div className={b('check-box')}>
          <CheckBox
            checked={s.active}
            name={s.value}
            onChange={() => {
              const currentItem = state.statuses.find(s2 => s2.value === s.value);
              currentItem.active = !currentItem.active;
              onChangeStatus([...state.statuses]);
            }}
          />
        </div>
        <span className={b('check-box-text')}>{locale[s.name]}</span>
      </div>
    ));
  }, [statusItems, onChangeStatus, state.statuses, locale]); */

  return (
    <form className={b()} onSubmit={onSubmit}>
      <div className={b('items')}>
        {/* <div className={b('item-check-boxes1')}>
          <span className={b('item-check-boxes-label')}>{locale.show}</span>
          <div className={b('item-check-boxes-list')}>{typeCheckboxes}</div>
        </div> */}

        {/* <div className={b('item-check-boxes2')}>
          <span className={b('item-check-boxes-label')}>{locale.show}</span>
          <div className={b('item-check-boxes-list')}>{statusCheckboxes}</div>
        </div> */}

        <div className={b('item-double')}>
          <span className={b('item-double-label')}>{locale.userId}</span>
          <span className={b('item-double-label')}>{locale.gameType}</span>
          <div className={b('item-double-input')}>
            <Input value={state.userId} onChange={onChangeUserId} type="number" placeholder={locale.userId} />
          </div>
          <div className={b('item-double-input')}>
            <Select
              items={gameTypeItems}
              activeItem={state.gameTypes.find(p => p.active) || state.gameTypes[0]}
              placeholder={locale.allGameTypes}
              onChange={value => {
                const updatedItems = state.gameTypes.map(p => ({ ...p, active: p.value === value }));
                onChangeGameType(updatedItems);
              }}
            />
          </div>
        </div>

        <label className={b('item-selector')}>
          <span className={b('item-selector-label')}>{locale.provider}</span>
          <div className={b('item-selector-input')}>
            <Select
              items={providerItems}
              activeItem={state.providers.find(p => p.active) || state.providers[0]}
              placeholder={locale.allProviders}
              onChange={value => {
                const updatedItems = state.providers.map(p => ({ ...p, active: p.value === value }));
                onChangeProvider(updatedItems);
              }}
            />
          </div>
        </label>

        <div className={b('item-dates')}>
          <span className={b('item-dates-title')}>{locale.date}</span>
          <div className={b('item-dates-container')}>
            <span className={b('item-dates-label')}>{locale.from}</span>
            <span className={b('item-dates-label')}>{locale.to}</span>
            <div className={b('item-dates-input')}>
              <InputDate dateFormat="yyyy-MM-dd" selected={new Date(state.from)} onChange={onChangeFrom} />
            </div>
            <div className={b('item-dates-input')}>
              <InputDate dateFormat="yyyy-MM-dd" selected={new Date(state.to)} onChange={onChangeTo} />
            </div>
          </div>
        </div>
      </div>

      <div className={b('button')}>
        <Button type="submit">
          <span className={b('button-text')}>{locale.showResults}</span>
        </Button>
      </div>
    </form>
  );
};

SlotsHistoryFilter.propTypes = {};

export default SlotsHistoryFilter;
